import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "@algolia/autocomplete-theme-classic";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./assets/css/App.css";
import App from "./app/App";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
   <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
