import { PartialDeep } from 'type-fest';
import _ from 'lodash';

export type User = {
    id: number | null;
    customerNumber: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    defaultWarehouse: string | null;
    permissions: string[];
    isAdmin: boolean | null;
    defaultShipTo: string | null;
    cartItems: number | null;
    exclusiveCustomer: boolean | null;
    hasMostPurchased: boolean | null;
    impersonatedCustomer: string | null;
    accountMenu: AccountMenu | null;
    menu: Menu
};

export type Menu = {
    shopProductsVisible: boolean
    menuItems: MenuItem[];
}

export type MenuItem = {
    id: number;
    name: string;
    seName: string;
    subCategories: MenuItem[];
}

export type AccountMenu = {
    myDashboardVisible: boolean;
    onlineBillPayVisible: boolean;
    customerAccountVisible: boolean;
    userProfileVisible: boolean;
    manageUsersVisible: boolean;
    orderNotificationsVisible: boolean;
    shipToVisible: boolean;
};

function userModel(data: PartialDeep<User>): User {
    data = data || {};

    return _.defaults(data, {
        id: null,
        customerNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        defaultWarehouse: null,
        permissions: [],
        isAdmin: null,
        menu: {
            shopProductsVisible: false,
            menuItems: []
        },
        defaultShipTo: null,
        cartItems: null,
        exclusiveCustomer: null,
        hasMostPurchased: false,
        impersonatedCustomer: null,
        accountMenu: {
            myDashboardVisible: false,
            onlineBillPayVisible: false,
            customerAccountVisible: false,
            userProfileVisible: false,
            manageUsersVisible: false,
            orderNotificationsVisible: false,
            shipToVisible: false
        }
    });
}

export default userModel;
