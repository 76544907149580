export const buttonTypes = [
    'primary',
    'secondary',
    'error',
    'warning',
    'info',
    'success',
    'inherit text',
    'inherit white',
    'mint green'
] as const;

// Derive the type ButtonType from the array
export type ButtonType = typeof buttonTypes[number];

export const buttonVariants = [
    'contained',
    'outlined',
    'text'
] as const;

// Derive the type ButtonVariant from the array

export type ButtonVariant = typeof buttonVariants[number];

export const buttonSizes = [
    'large',
    'medium',
    'small'
] as const;

// Derive the type ButtonSize from the array

export type ButtonSize = typeof buttonSizes[number];




