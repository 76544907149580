  export type BaseResponse<T> = {
    data: T;
    success?: boolean;
    message?: string;
    error?: string;
    version?: string;
};

export type Token = {
  name: string;
  value: string;
}

const enum MapTypeEnum {
  UseMyCurrentLocation = 1,
  SearchText = 2,
  CurrentBranch = 3
}

export default MapTypeEnum;
