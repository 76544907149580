import axios from "axios";

interface ITokenProvider {
  fetchToken(): Promise<string | null>;
  getToken(): string | null;
  clearToken(): void;
}

class TokenProvider implements ITokenProvider {
  private tokenKey = "access_token";
  private apiUrl = process.env.REACT_APP_WEB_URL;

  async fetchToken(): Promise<string | null> {
    const response = await axios.get(`${this.apiUrl}/JwtToken`, {
      withCredentials: true,
    });

    if (response.status === 401 || response.data.Success === false) {
      this.clearToken();
      return null;
    }

    const token = response.data.AccessToken;
    localStorage.setItem(this.tokenKey, token);
    return token;
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  clearToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
}

const tokenProvider = new TokenProvider();

export default tokenProvider;
