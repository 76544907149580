import React, { useEffect, useState } from 'react';
import BuilderIOPage from '../../builderio/components/builderIOPage';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useGetImpactProgramsInfoQuery } from '../../store/services/dataApi';
import PageLoading from '../../components/shared/pageLoading';

const ImpactPrograms: React.FC = () => {
    const [builderIOData, setBuilderIOData] = useState<any>();

    const warehouse = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);
    const { data, isLoading } = useGetImpactProgramsInfoQuery(warehouse.warehouseCode);
    const result = data?.data;

    useEffect(() => {
        if (result === undefined) return;

        setBuilderIOData({
            leadsVisible: result!.proZoneVisible,
            impactRewardsVisible: result!.impactRewardsVisible,
            loyaltyLinkVisible: result!.savrVisible,
            marketingServicesVisible: result!.marketingServicesVisible,
            financingSolutionsVisible: result!.greenFinancingVisible
        });

    }, [result]);

    const stateChangedHandler = (state: any) => {
        if (state.leadsVisible === undefined)
            return;

        if (
            builderIOData?.leadsVisible !== state.leadsVisible ||
            builderIOData?.impactRewardsVisible !== state.impactRewardsVisible ||
            builderIOData?.loyaltyLinkVisible !== state.loyaltyLinkVisible ||
            builderIOData?.marketingServicesVisible !== state.marketingServicesVisible ||
            builderIOData?.financingSolutionsVisible !== state.financingSolutionsVisible
        ) {
            setBuilderIOData({
                leadsVisible: state.leadsVisible,
                impactRewardsVisible: state.impactRewardsVisible,
                loyaltyLinkVisible: state.loyaltyLinkVisible,
                marketingServicesVisible: state.marketingServicesVisible,
                financingSolutionsVisible: state.financingSolutionsVisible
            });
        }
    };

    if (isLoading) {
        return <PageLoading />;
    }

    return (
        <BuilderIOPage
            data={builderIOData}
            onStateChange={stateChangedHandler}
            url="/impact-programs/impact-programs-overview" />
    );
};

export default ImpactPrograms;