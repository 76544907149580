import HomePageCategory from './homepageCategory';
import categorySidingImg from '../../../img/category-siding.jpeg';
import categoryRoofingImg from '../../../img/category-roofing.jpeg';
import categoryHousewrapImg from '../../../img/category-housewrap-insulation.jpeg';
import categoryMillworkImg from '../../../img/category-millwork-trim.jpeg';
import categoryGutterImg from '../../../img/category-gutter.jpeg';
import categoryPoolPatioImg from '../../../img/category-pool-patio.png';
import categoryDeckingImg from '../../../img/category-decking.jpeg';
import categoryRailingImg from '../../../img/category-railing.jpeg';
import categoryShuttersImg from '../../../img/category-shutters.jpeg';
import categorySkylightsImg from '../../../img/category-skylights.jpeg';
import categoryStormDoorsImg from '../../../img/category-storm-doors.jpeg';
import categoryWindowsImg from '../../../img/category-windows.jpeg';
import categoryEquipmentImg from '../../../img/category-equipment-tools.jpeg';
import categoryCaulkingImg from '../../../img/category-caulking-sealant-adhesives.jpeg';
import categoryFastenersImg from '../../../img/category-fasteners.jpeg';

export interface HomePageCategoriesProp {
    categoryName: string,
    categoryImage: string
}

const HomePageCategories = () => {
    let categories: HomePageCategoriesProp[] = [
        {
            categoryName: "Siding",
            categoryImage: categorySidingImg
        },
        {
            categoryName: "Roofing",
            categoryImage: categoryRoofingImg
        },
        {
            categoryName: "Housewrap & Insulation",
            categoryImage: categoryHousewrapImg
        },
        {
            categoryName: "Millwork & Trim",
            categoryImage: categoryMillworkImg
        },
        {
            categoryName: "Gutter",
            categoryImage: categoryGutterImg
        },
        {
            categoryName: "Pool & Patio Enclosures",
            categoryImage: categoryPoolPatioImg
        },
        {
            categoryName: "Decking",
            categoryImage: categoryDeckingImg
        },
        {
            categoryName: "Railing",
            categoryImage: categoryRailingImg
        },
        {
            categoryName: "Shutters",
            categoryImage: categoryShuttersImg
        },
        {
            categoryName: "Skylights",
            categoryImage: categorySkylightsImg
        },
        {
            categoryName: "Storm Doors",
            categoryImage: categoryStormDoorsImg
        },
        {
            categoryName: "Windows",
            categoryImage: categoryWindowsImg
        },
        {
            categoryName: "Equipment & Tools",
            categoryImage: categoryEquipmentImg
        },
        {
            categoryName: "Caulking, Sealant & Adhesives",
            categoryImage: categoryCaulkingImg
        },
        {
            categoryName: "Fasteners",
            categoryImage: categoryFastenersImg
        }];    

    return (
        <>
            {
                categories.map(category => (
                    <HomePageCategory 
                        key={category.categoryName}
                        categoryName={category.categoryName} 
                        categoryImage={category.categoryImage} />
                ))
            }
        </>
    );  
}

export default HomePageCategories;