import { baseApi } from './baseApi';
import { ImpactProgramsVisibility } from '../../models/customer/types';
import { BaseResponse } from '../../models/common/types';
import { Warehouse } from '../../models/warehouse/types';
import { VersionURLParam } from '../../utils/versionUrl';
import { ProductDetails } from '../../models/product/types';

export const dataApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        GetAllBranches: builder.query<BaseResponse<Warehouse[]>, string | null>({
            query: (warehouseCode) => ({
                url: `GetAllBranches?warehouseCode=${warehouseCode}&${VersionURLParam()}`,
                method: 'GET',
            }),
        }),
        GetSortedCategories: builder.query<BaseResponse<string[]>, void>({
            query: () => ({
                url: 'GetSortedCategories',
                method: 'GET',
            }),
        }),
        GetImpactProgramsInfo: builder.query<BaseResponse<ImpactProgramsVisibility>, string | null>({
            query: (warehouseCode) => ({
                url: `ImpactProgramsInfo?warehouseCode=${warehouseCode}&${VersionURLParam()}`,
                method: 'GET',
            }),
        }),
        GetProductDetails: builder.query<BaseResponse<ProductDetails>, { id: string | null, warehouseCode: string | null }>({
            query: ({ id, warehouseCode }) => ({
                url: `GetProductDetails?id=${id}&warehouseCode=${warehouseCode}&${VersionURLParam()}`,
                method: 'GET',
            }),
        })
    }),
});

export const {
    useGetAllBranchesQuery,
    useGetSortedCategoriesQuery,
    useGetImpactProgramsInfoQuery,
    useGetProductDetailsQuery
} = dataApi;
