import { Token } from "../../../app/models/common/types";

export const spaces: { [key: string]: string } = {
    "Spacing1": "8px",
    "Spacing2": "16px",
    "Spacing3": "24px",
    "Spacing4": "32px",
    "Spacing5": "40px",
    "Spacing6": "48px",
    "Spacing7": "56px",
    "Spacing8": "64px",
}

const spacesTokens: Token[] = [];

for (const space in spaces) {
    spacesTokens.push({
        name: space.replace(/(\d+)/g, ' $1').trim(),
        value: spaces[space]
    });
}

export default spacesTokens;
