import { Button as MuiButton } from "@mui/material";
import { ButtonSize, buttonSizes, ButtonType, buttonTypes, ButtonVariant, buttonVariants } from "./types";
import buttonStyles from "../../../../assets/css/styles/buttonStyle";

interface ButtonProps {
  text: string;
  variant: ButtonVariant;
  type: ButtonType;
  size: ButtonSize;
}

const Button: React.FC<ButtonProps> = ({ text = "button", variant = "contained", type = "primary", size = "large" }) => {
  const className = `${variant}-${type}-${size}`.replace(/\s+/g, '-');

  const style = buttonStyles[className] || {};

  return (
    <MuiButton sx={style || undefined}>{text}</MuiButton>
  );
};

export default Button;

export const buttonRegistration = {
  name: "Button",
  inputs: [
    {
      name: "text",
      type: "string",
    },
    {
      name: "variant",
      type: "string",
      enum: buttonVariants,
      defaultValue: "contained",
    },
    {
      name: "type",
      type: "string",
      enum: buttonTypes,
      defaultValue: "primary",
    },
    {
      name: "size",
      type: "string",
      enum: buttonSizes,
      defaultValue:"large",
    }
  ],
  image: "https://unpkg.com/css.gg@2.0.0/icons/svg/shape-square.svg",
  description: "Add a button to the page",
};