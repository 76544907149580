import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import userModel, { User } from "../../models/auth/types";
import axiosService from "../../services/axiosService";
import { URLS } from "../../services/constants";
import { BaseResponse } from "../../models/common/types";

// Define UserState interface

export type UserState = {
    user: User;
    isUserLoaded: boolean;
    isAuthenticated: boolean;
};

const initialState: UserState = {
    user: userModel({}),
    isUserLoaded: false,
    isAuthenticated: false,
};

export const getUserProfileDataAsync = createAsyncThunk(
    "user/getUserProfileDataAsync",
    async () => {
        try {
            const response = await axiosService.get<BaseResponse<User>>(
                URLS.CUSTOMER_PROFILE_URL
            );

            return response?.data.data;
        } catch {
            return initialState.user;
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUser(state, action: PayloadAction<User>) {
            Object.assign(state, action.payload);
        },
        userSignOut(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProfileDataAsync.fulfilled, (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isUserLoaded = true;
            state.isAuthenticated = action.payload.id !== null && action.payload.id !== 0;
        });
        builder.addCase(getUserProfileDataAsync.pending, (state) => {
            state.isUserLoaded = false;
        });
    },
});

export const { updateUser, userSignOut } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.userState.user;
