import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Warehouse, WarehouseInfo } from "../../models/warehouse/types";
import axiosService from "../../services/axiosService";
import { URLS } from "../../services/constants";
import moment from "moment";
import { RootState } from "../store";
import { Logger } from "../../logger/logger";
import { BaseResponse } from "../../models/common/types";
import expiringLocalStorage from "../../services/expiringLocalStorage";

interface WarehouseState {
  warehouseInfo: WarehouseInfo;
  selectedWarehouse: Warehouse;
  isWarehouseInfoLoaded: boolean;
  openRequestedAt: Date | null;
}

const initialState: WarehouseState = {
  warehouseInfo: {
    warehouses: [],
    hoursFrom: null,
    hoursTo: null,
    isNEDivision: false,
    branchMessage: null,
    globalMessage: null,
    selectedWarehouseCode: null
  },
  selectedWarehouse: {
    warehouseName: null,
    warehouseCode: null,
    warehouseFullAddress: null,
    warehouseAddress: null,
    warehouseCity: null,
    warehouseState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
  },
  isWarehouseInfoLoaded: false,
  openRequestedAt: null
};
// Async thunk to fetch the nearest warehouse details and then fetch its info
export const fetchWarehouseDetails = createAsyncThunk(
  "warehouse/fetchWarehouseDetails",
  async (selectedWarehouse: string | null, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const isAuthenticated = state.userState.isAuthenticated;
      let warehouse: string | null = selectedWarehouse;
      if (!isAuthenticated && !warehouse) {
        var storedWarehouse = expiringLocalStorage.getItem(expiringLocalStorage.localStorageKeys.geolocatedWarehouse);
        if (storedWarehouse === null) {
          const warehouseResponse = await axiosService.get<BaseResponse<string>>(
            URLS.NEAREST_WAREHOUSE_URL
          );

          if (!warehouseResponse.data.success) rejectWithValue(warehouseResponse.data.error);

          warehouse = warehouseResponse.data.data;
        }
        else {
          warehouse = storedWarehouse;
        }
      } else if (!warehouse) {
          warehouse = state.userState.user.defaultWarehouse;
      }

      const clientUtcOffsetInMinutes = moment().utcOffset();
      //TODO: move to warehouse service
      const response = await axiosService.get<BaseResponse<WarehouseInfo>>(
        URLS.WAREHOUSE_INFO_URL,
        {
          params: {
            clientUtcOffsetInMinutes,
            warehouseCode: warehouse,
          },
        }
      );

      if (!response.data.success) rejectWithValue(response.data.error);
      response.data.data.selectedWarehouseCode = warehouse;

      return response.data.data;
    } catch (e) {
      console.log(e);
      rejectWithValue("Error in fetching nearest warehouse and info");
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setOpenRequestedAt: (state, action: PayloadAction<Date>) => {
      state.openRequestedAt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchWarehouseDetails.fulfilled,
        (state, action: PayloadAction<WarehouseInfo | undefined>) => {
          if (action.payload === undefined) return;
          state.warehouseInfo = action.payload!;
          state.isWarehouseInfoLoaded = true;
          if (action.payload!.selectedWarehouseCode != null)
            state.selectedWarehouse = action.payload!.warehouses.filter(
              (x) => x.warehouseCode === action.payload!.selectedWarehouseCode
            )[0];
        }
      )
      .addCase(fetchWarehouseDetails.pending, (state) => {
        state.isWarehouseInfoLoaded = false;
      })
      .addCase(fetchWarehouseDetails.rejected, (state, action) => {
        Logger.error("Failed to fetch warehouse details:", action.error.message);
      });
  },
});

export default warehouseSlice.reducer;

export const { setOpenRequestedAt } = warehouseSlice.actions;