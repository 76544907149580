import { Token } from "../../../app/models/common/types";

export const colors: { [key: string]: string } = {
    "LansingTextPrimary": "#000000DE",
    "LansingTextSecondary": "#00000099",
    "LansingTextDisabled":"#00000061",
    "LansingPrimaryGrey": "#707372",
    "LansingPrimaryBlue": "#62B5E5",
    "LansingNavyBlue": "#003B5C",
    "LansingBlue": "#003B5C",
    "LansingGreen": "#64A70B",
    "LansingGold": "#F1B434",
    "LansingOrange": "#B86125",
    "LansingMidGreen": "#00B388",
    "LansingBurgundy": "#7C2529",
    "LansingTeal": "#40C1AC",
    "LansingRed": "#AF272F",
    "LansingDarkerPrimaryBlue": "#2C7EAC",
    "LansingDarkerMidGreen": "#007C82",
    "LansingDarkerNavyBlue": "#002C4A",
    "LansingDarkerOrange": "#613114",
    "LansingWhite": "#FFFFFF",
}

const colorTokens: Token[] = [];

for (const color in colors) {
    colorTokens.push({
        name: color.replace(/([A-Z])/g, ' $1').trim(),
        value: colors[color]
    });
}

export default colorTokens;
