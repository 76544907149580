import { Provider } from "react-redux";
import ErrorBoundary from "./components/shared/errorBoundary";
import AppContext from "./AppContext";
import { store } from "./store/store";

type ComponentProps = {
  name?: string;
};

/**
 * A Higher Order Component that provides the necessary context providers for the app.
 */
function withAppProviders(Component: React.ComponentType<ComponentProps>) {
  /**
   * The component that wraps the provided component with the necessary context providers.
   */
  function WithAppProviders(props: React.PropsWithChildren<ComponentProps>) {
    /**
     * The value to pass to the AppContext provider.
     */
    return (
      <ErrorBoundary>
        <AppContext.Provider value={{}}>
          <Provider store={store}>
            <Component {...props} />
          </Provider>
        </AppContext.Provider>
      </ErrorBoundary>
    );
  }

  return WithAppProviders;
}

export default withAppProviders;
