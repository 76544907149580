
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Dialog, DialogTitle, IconButton, DialogContent, Button } from '@mui/material';

interface CustomWindowsProductPicturesProps {
    windowGalleryPictures: string[];
}

const CustomWindowsProductPictures:React.FC<CustomWindowsProductPicturesProps> = ({ windowGalleryPictures }) => {
    const [windowGalleryImageOpen, setWindowGalleryImageOpen] = useState(false);
    const [selectedWindowGalleryImage, setSelectedWindowGalleryImage] = useState<string | null>(null);

    const handleWindowGalleryDialogPrevious = () => {
        let currentIndex = windowGalleryPictures.indexOf(selectedWindowGalleryImage ?? "");
        if (currentIndex === 0)
            currentIndex = windowGalleryPictures.length - 1;
        else if (currentIndex > 0)
            currentIndex--;
        else
            return;

        setSelectedWindowGalleryImage(windowGalleryPictures[currentIndex]);
    };

    const handleWindowGalleryDialogNext = () => {
        let currentIndex = windowGalleryPictures.indexOf(selectedWindowGalleryImage ?? "");
        if (currentIndex === windowGalleryPictures.length - 1)
            currentIndex = 0;
        else if (currentIndex < windowGalleryPictures.length - 1)
            currentIndex++;
        else
            return;

        setSelectedWindowGalleryImage(windowGalleryPictures[currentIndex]);
    };
    
    const handleWindowGalleryImageOpen = (image: string) => {
        setWindowGalleryImageOpen(true);
        setSelectedWindowGalleryImage(image);
    };

    const handleWindowGalleryImageClose = () => {
        setWindowGalleryImageOpen(false);
        setSelectedWindowGalleryImage(null);
    };

    return windowGalleryPictures.length === 0 ? <></> : (
            <>
            <h1 className='text-center'>Gallery</h1>
            <Carousel
                indicators={false}
                navButtonsAlwaysVisible={true}
                autoPlay={true}
                animation='slide'
                sx={{mb:5}}
                cycleNavigation={true}
                NextIcon={<ArrowForwardIosIcon  />}
                PrevIcon={<ArrowBackIosIcon style={{position:"relative", left:"4px"}}  />}>       
                {windowGalleryPictures.map((image, index) => (
                <Grid container spacing={2} justifyContent="center" key={index} className='gallerySlideshow'>
                    <Grid item xs={2.4}className='gallerySlides'>
                    <Box
                        onClick={() => handleWindowGalleryImageOpen(windowGalleryPictures[(index + 0) % windowGalleryPictures.length])}
                        component="img"
                        className='handCursor'
                        src={windowGalleryPictures[(index + 0) % windowGalleryPictures.length]}
                        alt={`Slide ${index}`}
                    />
                    </Grid>
                    <Grid item xs={2.4}className='gallerySlides'>
                    <Box
                        onClick={() => handleWindowGalleryImageOpen(windowGalleryPictures[(index + 1) % windowGalleryPictures.length])}
                        component="img"
                        className='handCursor'
                        src={windowGalleryPictures[(index + 1) % windowGalleryPictures.length]}
                        alt={`Slide ${index}`}
 
                    />
                    </Grid>
                    <Grid item xs={2.4}className='gallerySlides'>
                    <Box
                        onClick={() => handleWindowGalleryImageOpen(windowGalleryPictures[(index + 2) % windowGalleryPictures.length])}
                        component="img"
                        className='handCursor'
                        src={windowGalleryPictures[(index + 2) % windowGalleryPictures.length]}
                        alt={`Slide ${index}`}
 
                    />
                    </Grid>
                    <Grid item xs={2.4}className='gallerySlides'>
                    <Box
                        onClick={() => handleWindowGalleryImageOpen(windowGalleryPictures[(index + 3) % windowGalleryPictures.length])}
                        component="img"
                        className='handCursor'
                        src={windowGalleryPictures[(index + 3) % windowGalleryPictures.length]}
                        alt={`Slide ${index}`}
 
                    />
                    </Grid>
                    <Grid item xs={2.4}className='gallerySlides'>
                    <Box
                        onClick={() => handleWindowGalleryImageOpen(windowGalleryPictures[(index + 4) % windowGalleryPictures.length])}
                        component="img"
                        className='handCursor'
                        src={windowGalleryPictures[(index + 4) % windowGalleryPictures.length]}
                        alt={`Slide ${index}`}
 
                    />
                    </Grid>
                </Grid>
                ))}   
            </Carousel>
            
            <Dialog                
                maxWidth='xl'
                sx={{
                    '& .MuiDialog-paper': {
                      width: '90%',
                      height: '90%',
                      maxWidth: 'none',
                      margin: '0px',
                    },
                  }}                
                open={windowGalleryImageOpen}
                onClose={handleWindowGalleryImageClose}
                >
                <DialogTitle>
                    <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleWindowGalleryImageClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 15, top: 5 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            <DialogContent className='dialogFullsizeImage'>
                {selectedWindowGalleryImage && (
                    <>
                        <Button onClick={() => handleWindowGalleryDialogPrevious()}
                            className='carouselBackButton'>
                            <ArrowBackIosIcon  />
                        </Button>
                        <Box
                            component="img"
                            src={selectedWindowGalleryImage}
                            alt="Fullscreen"
                            sx={{
                            width: '98%',
                            height: '98%',
                            objectFit: 'contain',
                            }}
                        />

                        <Button onClick={() => handleWindowGalleryDialogNext()}
                            className='carouselNextButton'>
                            <ArrowForwardIosIcon />
                        </Button>
                    </>
                )}
            </DialogContent>
            </Dialog>
        </>);
}

export default CustomWindowsProductPictures;