import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Box, Button, Skeleton, Grid, Container, Autocomplete, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomWindowsProductPictures from './customWindowsProductPictures';
import PageTitle from '../../utils/pageTitle';
import defaultProductImg from '../../../img/default-image_420.png';
import ProductSpecificationTabs from './productSpecificationTabs';
import ProductSpecificationFlat from './productSpecificationFlat';
import SeoIgnore from "../../utils/seoIgnore";
import { useGetProductDetailsQuery } from '../../store/services/dataApi';
import { ProductDetailsDocument, ProductDetailsSpecification, ProductDetailsVariant } from '../../models/product/types';
import dataService from '../../services/dataService';

const registerUrl = process.env.REACT_APP_WEB_URL !== null ? process.env.REACT_APP_WEB_URL + "/register" : "";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export interface WarehouseProps {
    warehouseCode: string | null;
}


const Product: React.FC<WarehouseProps> = ({warehouseCode}) => {

    const query = useQuery();
    let id: string | null = query.get("id") ?? null;

    const getLoginUrl = (productId: string | null, variantId: number | null) => {
        if (productId === null)
            return `${process.env.REACT_APP_WEB_URL}/quicklogin`;

        let returnUrl: string = `/p/${productId}`;

        if (variantId !== null)
            returnUrl += `?associatedproduct=${variantId}`;

        let returnUrlEncoded: string = encodeURIComponent(returnUrl);

        let quickLoginUrl: string = `${process.env.REACT_APP_WEB_URL}/quicklogin?returnUrl=${returnUrlEncoded}`;
        return quickLoginUrl;
    }

    const handleOnLogin = (productId: string | null, variantId: number | null) => {
        window.location.href = getLoginUrl(productId, variantId);
    }

    const handleOnRegisterNow = () => {
        window.location.href = registerUrl;
    }

    const [loading, setLoading] = useState(true);

    const [sku, setSku] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [brand, setBrand] = useState<string | null>(null);
    const [topSellingVariant, setTopSellingVariant] = useState<ProductDetailsVariant | null>(null);
    const [category, setCategory] = useState<string | null>(null);
    const [variantType, setVariantType] = useState<string | null>(null);
    const [variants, setVariants] = useState<ProductDetailsVariant[]>([]);
    const [aboutText, setAboutText] = useState<string | null>(null);
    const [embeddedVideo, setEmbeddedVideo] = useState<string | null>(null);
    const [specifications, setSpecifications] = useState<ProductDetailsSpecification[]>([]);
    const [documents, setDocuments] = useState<ProductDetailsDocument[]>([]);
    const [pictures, setPictures] = useState<string[]>([]);
    const [windowGalleryPictures, setWindowGalleryPictures] = useState<string[]>([]);
    const [mainPicture, setMainPicture] = useState<string>(defaultProductImg);
    const [mainPictureOverlay, setMainPictureOverlay] = useState<string | null>(null);
    const [availability, setAvailability] = useState<string | null>(null);
    const [customWindows, setCustomWindows] = useState<boolean>(false);
    const [customWindowPrice, setCustomWindowPrice] = useState<string | null>(null);
    const [variantId, setVariantId] = useState<number | null>(null);

    const { data: response, error, isLoading } = useGetProductDetailsQuery({ id, warehouseCode });

    if (error) {
        setLoading(false);
    }

    useEffect(() => {

        if (!isLoading) {
            const data = response!.data;

            PageTitle(data === null ? "Product Details" : data.name);

            let aboutTextResponse: string | null = DOMPurify.sanitize(data.fullDescription);
            let embeddedVideoResponse: string | null = data.embeddedVideo;

            setName(data.name);
            setBrand(data.brand);
            setVariantType(data.type);
            setVariants(data.variants);
            setCategory(data.category);
            setAboutText(aboutTextResponse);
            setEmbeddedVideo(embeddedVideoResponse);
            setDocuments(data.attachments);
            setCustomWindows(data.customWindows);
            setCustomWindowPrice(data.customWindowsPrice);

            if (data.variantDetails != null) {
                let variantDetails = data.variantDetails;
                let topSellingVariantId = variantDetails.id;
                if (topSellingVariantId != null) {
                    setTopSellingVariant(data.variants.find((x: ProductDetailsVariant) => x.id === topSellingVariantId) ?? null);
                }
                applySelectedVariantProps(variantDetails.id, variantDetails.pictures, variantDetails.sku, variantDetails.overlayRGB, variantDetails.inventoryStatus);

                setSpecifications(variantDetails.specifications);
            } else {
                
                let picturesResponse: string[] = data.pictures;
                let pictureURLs: string[] = [];
                if (picturesResponse.length > 0)
                    pictureURLs = picturesResponse.map(x => `${process.env.REACT_APP_CDN_URL}/images/general/picture_${x}`);
                else
                    pictureURLs = [defaultProductImg];

                setPictures(pictureURLs);
                setMainPicture(pictureURLs[0]);

                let windowGalleryResponse: string[] = data.windowGallery;
                setWindowGalleryPictures(windowGalleryResponse.map(x => `${process.env.REACT_APP_CDN_URL}/images/general/picture_${x}`));

                setSku(data.sku);
                setAvailability(data.inventoryStatus);
                setSpecifications(data.specifications);

                if (data.overlayRGB != null)
                    setMainPictureOverlay(data.overlayRGB);
            }

            setLoading(false);
        }
    }, [response,isLoading]);

    const applySelectedVariantProps = (variantId: number, pictures: string[], sku: string | null, overlayRGB: string | null, inventoryStatus: string | null) => {
        let picturesResponse: string[] = pictures;
        let pictureURLs: string[] = [];
        if (picturesResponse.length > 0)
            pictureURLs = picturesResponse.map(x => `${process.env.REACT_APP_CDN_URL}/images/general/picture_${x}`);
        else
            pictureURLs = [defaultProductImg];

        setPictures(pictureURLs);
        setMainPicture(pictureURLs[0]);

        setSku(sku);
        setAvailability(inventoryStatus);

        setWindowGalleryPictures([]);

        setVariantId(variantId);

        if (overlayRGB != null)
            setMainPictureOverlay(overlayRGB);
    }

    const handleVariantChange = async (event: React.SyntheticEvent, value: ProductDetailsVariant) => {
        setTopSellingVariant(value);

        if (warehouseCode == null)
            return;

        const response = await dataService.getVariantDetails(value.id, warehouseCode);

        const variantDetails = response.data;
        applySelectedVariantProps(variantDetails.id, variantDetails.pictures, variantDetails.sku, variantDetails.overlayRGB, variantDetails.inventoryStatus);

        setLoading(false);
    };

    return (
        <div className='page-margin'>
            <SeoIgnore></SeoIgnore>
            <div className='text-center'>
                <div className='page-title'>
                    <h1>{category}</h1>
                </div>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item md={6} className='fullsizeTablet'>
                        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ textAlign: "center" }}>
                            {loading ?
                                <Skeleton variant="rectangular" animation="wave" width={500} height={500} /> :
                                <img style={{ maxHeight: "500px", maxWidth: "90%", backgroundColor: mainPictureOverlay ?? "" }} src={mainPicture} alt="Product main" />
                            }

                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ textAlign: "center", mt: 3 }}>
                            {pictures.length <= 1 ? <></> :
                                <div className="productDetailsSecondaryContainer">
                                    {
                                        pictures.map((picture, index) => (
                                            <div key={picture} className='productDetailsSecondaryImage handCursor' onClick={() => setMainPicture(picture)}
                                                style={{ backgroundColor: index === 0 && mainPictureOverlay ? mainPictureOverlay : "" }}>
                                                <img src={picture} alt="Product carousel" />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="h4" gutterBottom sx={{ fontSize: 40, mb: 5, fontWeight: 600, color: "#535353 !important" }}>
                            {loading ? <Skeleton /> : <>{name}</>}
                        </Typography>
                        {customWindows ? (
                            <>
                                <Typography variant="body1" gutterBottom>
                                    {loading ? <Skeleton /> : (
                                        brand === null ? <></> : <>Brand: <b>{brand}</b></>
                                    )}
                                </Typography>
                                {brand !== null && brand.includes("Harvey") ? (
                                    <Typography variant="body1" gutterBottom>
                                        <Link to={"/programs/clearwin"}
                                            style={{ textDecoration: 'none' }}>
                                            Clearwin
                                        </Link>
                                    </Typography>
                                ) : <></>}
                                <Typography variant="body1" gutterBottom>
                                    {loading ? <Skeleton /> : (
                                        availability === null ? <></> : <>Availability: <b><span style={{
                                            color: availability === "Stocked" ? "green"
                                                : availability === "Stocked in Network" ? "green"
                                                    : availability === "Special Order" || customWindows ? "red" : ""
                                        }}>{availability}</span></b></>
                                    )}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" gutterBottom>
                                    {loading ? <Skeleton /> : (
                                        availability === null ? <></> : <>Availability: <b><span style={{
                                            color: availability === "Stocked" ? "green"
                                                : availability === "Stocked in Network" ? "green"
                                                    : availability === "Special Order" || customWindows ? "red" : ""
                                        }}>{availability}</span></b></>
                                    )}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {loading ? <Skeleton /> : (
                                        brand === null ? <></> : <>Brand: <b>{brand}</b></>
                                    )}
                                </Typography>
                            </>
                        )}
                        <Typography variant="body1" gutterBottom>
                            {loading ? <Skeleton /> : <>SKU: <b>{sku}</b></>}
                        </Typography>
                        {customWindows ? (<Typography variant="body1" gutterBottom style={{ fontSize: "24px" }}>
                            {loading ? <Skeleton /> :
                                <span style={{ letterSpacing: "4px", fontWeight: "500" }}>
                                    <span>{customWindowPrice}</span>
                                    <span style={{ color: "#777777" }}>{"$".repeat((5 - (customWindowPrice ?? "").length))}</span>
                                </span>}
                        </Typography>) : <></>}
                        <div style={{ marginTop: "40px" }}>
                            {loading ?
                                <Skeleton variant="rectangular" animation="wave" height={40} /> :
                                <>
                                    {variantType === null ? (<></>) : (
                                        <>
                                            <div>{variantType}:</div>

                                            <Autocomplete
                                                options={variants}
                                                value={topSellingVariant ?? undefined}
                                                getOptionLabel={(option) => option.name}
                                                groupBy={(option) => option.inventoryStatus}
                                                onChange={handleVariantChange}
                                                disableClearable
                                                renderOption={(props, option) => {
                                                    const { key } = props;
                                                    return (
                                                        <Box component="li" {...props} key={key} className='autocompleteColorSelector'>
                                                            {variantType === "Color"
                                                                ? (<Typography variant="body1" style={{ backgroundColor: option.colorSquaresRGB, width: "15px", height: "15px", marginRight: "10px", border: "1px solid #d3d3d3" }}></Typography>)
                                                                : <></>
                                                            }
                                                            <Typography variant="body1" color="textSecondary">{option.name}</Typography>
                                                        </Box>
                                                    )
                                                }}
                                                renderGroup={(params) => (
                                                    <li key={params.key}>
                                                        <Typography sx={{ padding: '5px 10px', pointerEvents: 'none', }}>
                                                            <strong>{params.group}</strong>
                                                        </Typography>
                                                        <ul style={{ padding: 0 }}>{params.children}</ul>
                                                    </li>
                                                )}
                                                renderInput={(params) => {
                                                    var colorSquaresRGB = variants.find(x => x.name === params.inputProps.value)?.colorSquaresRGB;

                                                    return (
                                                        <TextField
                                                            {...params}

                                                            inputProps={{ ...params.inputProps, readOnly: true }}
                                                            variant="standard"
                                                            sx={{ maxWidth: "380px" }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: params.inputProps.value && variantType === "Color" ? (
                                                                    <Typography variant="body1" style={{ backgroundColor: colorSquaresRGB, width: "15px", height: "15px", marginRight: "10px", border: "1px solid #d3d3d3" }}></Typography>
                                                                ) : null,
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                        </>
                                    )}
                                </>}
                        </div>

                        <Grid container spacing={2} direction="column" sx={{ mt: 3, maxWidth: "450px" }}>
                            <Grid item xs sx={{ mb: 2 }}>
                                <span style={{ fontWeight: 600 }}><a href={getLoginUrl(id, variantId)}>Log in</a> or <a href={registerUrl}>Register</a> for price <InfoOutlinedIcon style={{ position: "relative", top: "5px" }} color='primary' fontSize='small' /></span>
                            </Grid>
                            <Grid item xs>
                                {loading ? <Skeleton height={40} /> : <>
                                    <Button sx={{ mb: 1 }} className='blueButtonOutline w200 me-1' onClick={() => handleOnLogin(id, variantId)}>LOG IN &raquo;</Button>
                                </>}
                                {loading ? <Skeleton height={40} /> : <>
                                    <Button sx={{ mb: 1 }} className='blueButtonOutline w200' onClick={handleOnRegisterNow}>REGISTER NOW &raquo;</Button>
                                </>}
                            </Grid>
                        </Grid>
                        {customWindows ? (
                            <ProductSpecificationFlat
                                brand={brand}
                                aboutText={aboutText}
                                embeddedVideo={embeddedVideo}
                                specifications={specifications}
                                documents={documents}
                                loading={loading}>
                            </ProductSpecificationFlat>
                        ) : (
                            <ProductSpecificationTabs
                                brand={brand}
                                aboutText={aboutText}
                                embeddedVideo={embeddedVideo}
                                specifications={specifications}
                                documents={documents}
                                loading={loading}>
                            </ProductSpecificationTabs>
                        )}
                    </Grid>
                </Grid>

                <CustomWindowsProductPictures windowGalleryPictures={windowGalleryPictures} />
            </Container>
        </div>
    );
}

export default Product;