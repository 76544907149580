export const URLS = {
    CUSTOMER_PROFILE_URL: `CustomerProfile`,
    NEAREST_WAREHOUSE_URL: `GetNearestBranch`,
    WAREHOUSE_INFO_URL: `GetBranchInfo`,
    GET_ALL_BRANCHES_ORDERED_BY_LOCATION_URL: `GetAllWarehousesOrderedByLocation`,
    GET_ALL_BRANCHES: `GetAllBranches`,
    SET_SELECTED_WAREHOUSE: `SetSelectedWarehouse`,
    GET_SORTED_CATEGORIES: `GetSortedCategories`,
    GET_PRODUCT_DETAILS: 'GetProductDetails',
    GET_VARIANT_DETAILS: 'GetVariantDetails',
    GET_HEX_TO_COLOR_MAPPING: 'GetHexToColorMapping',
};