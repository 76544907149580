import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useCallback } from "react";

const useMenuItems = () => {

    const categories = useSelector((state: RootState) => state.userState.user.menu.menuItems);
    const subCategories = useSelector((state: RootState) => state.userState.user.menu.menuItems.map(item => item.subCategories).flat());
    const isShopProductsVisible = useSelector((state: RootState) => state.userState.user.menu.shopProductsVisible);
    const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);

    const hasCategory = useCallback((categoryToCheck: string): boolean => {
        return isAuthenticated ? categories.some((category) => categoryToCheck.includes(category.name)) : true;
    }, [isAuthenticated,categories]);

    const hasSubCategory = useCallback((subCategoryToCheck: string): boolean => {
        return isAuthenticated ? subCategories.some((subCategory) => subCategoryToCheck.includes(subCategory.name)) : true;
    }, [isAuthenticated, subCategories]);

    const shopProductsVisible = useCallback((): boolean => {
        return isAuthenticated ? isShopProductsVisible : true;
    }, [isAuthenticated, isShopProductsVisible]);

    return { hasCategory, hasSubCategory, shopProductsVisible };
}

export default useMenuItems;