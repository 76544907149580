import { useEffect } from "react";
import { getUserProfileDataAsync } from "../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";

const useAuthentication = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUserProfileDataAsync());
  }, [dispatch]);

  const userData = useSelector((state: RootState) => state.userState);
  const user = userData.user;

  //until we have one place for both visitor and authenticated user we should switch between those two urls
  useEffect(() => {
    if (!userData.isUserLoaded) return;

    const customerPath = "";//process.env.REACT_APP_STOREFRONT_SUBDIRECTORY!;
    const visitorPath = process.env.REACT_APP_SUBDIRECTORY!;
    const pathname = document.location.pathname;

    const isVisitor = user.id === null || user.id === 0;
    const isOnVisitorPath = pathname.includes(visitorPath);

    if (isVisitor && !isOnVisitorPath) {
      window.history.pushState({}, "", `/${visitorPath}`);
      window.location.reload();
    } else if (!isVisitor && isOnVisitorPath) {
      window.history.replaceState({}, "", `/${customerPath}`);
      window.location.reload();
    }
  }, [user.id, userData.isUserLoaded]);

  return {
    isAuthenticated: user.id !== null && user.id !== 0,
    isAuthenticating: !userData.isUserLoaded,
    user: user,
  };
};

export default useAuthentication;
