import { Token } from "../../../app/models/common/types";

export const fonts:{ [key: string]: string } = {
    "Gotham Medium": "Gotham-Medium",
    "Gotham Book": "Gotham-Book",
    "Gotham Black": "Gotham-Black",
    "Gotham Light":"Gotham-Light",
    "Droid Serif": "DroidSerif700",
    "Montserrat": "Montserrat",
    "Georgia": "georgia",
}

const fontTokens: Token[] = [];

for (const font in fonts) {
    fontTokens.push({
        name: font,
        value: fonts[font]
    });
}

export default fontTokens;