import axios from 'axios';
import tokenProvider from '../auth/tokenProvider';

// Create an instance of axios
const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_STOREFRONT_API_URL, 
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = await tokenProvider.getToken();

        const cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith('AuthenticatedCustomerId='))
                ?.split('=')[1];

        config.headers['AuthenticatedCustomerId'] = cookieValue?.trim();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to centralize error handling
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await tokenProvider.fetchToken();
            if (newToken) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
            } else {
                return Promise.reject(error);
            }
        }
        // Handle other errors centrally
        console.error('API call error:', error);
        // Optionally, you can add custom error handling logic here
        return Promise.reject(error);
    }
);

// The axiosInstance will be a singleton, meaning the same instance will be used across all imports.
// This is because the instance is created once and exported as the default export of the module.
export default axiosInstance;

