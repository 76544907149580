export class Logger {
    static log(...messages: any[]): void {
        console.log(`[LOG]: ${messages.join(' ')}`);
    }

    static info(...messages: any[]): void {
        console.info(`[INFO]: ${messages.join(' ')}`);
    }

    static warn(...messages: any[]): void {
        console.warn(`[WARN]: ${messages.join(' ')}`);
    }

    static error(...messages: any[]): void {
        console.error(`[ERROR]: ${messages.join(' ')}`);
    }
}