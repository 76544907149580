class ExpiringLocalStorage {

    localStorageKeys = {
        geolocatedWarehouse: `lansingnow_geolocated_warehouse`,
    }

    setItem(key: string, value: string, timeToLiveHours: number = 24): void {
        const now = new Date().getTime();
        const item = {
            value: value,
            expiry: now + (timeToLiveHours * 1000 * 60 * 60), // Convert ms to hours
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    getItem(key: string): string | null {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date().getTime();

        if (now > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }
}

const expiringLocalStorage = new ExpiringLocalStorage();
export default expiringLocalStorage;