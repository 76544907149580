import HomePageCategories from "./homepageCategories";
import HomepageProductTemplate from "./homepageProductTemplate";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HomepageFeatureZone from "./homepageFeatureZone";
import PageTitle from "../../utils/pageTitle";
import homepageImpactProgramsLogo from '../../../img/homepage-impact-programs-logo.png';
import homepageImpactProgramsPlusImg from '../../../img/homepage-impact-programs-plus.png';
import { Button } from "@mui/material";

const Homepage: React.FC = () => {
  PageTitle(null); // Set page title

  return (
    <div className="page-margin">
      <HomepageFeatureZone />

      <Container maxWidth="lg">
        <h1>Featured Categories</h1>
        <Grid container spacing={1}>
          <HomePageCategories />
        </Grid>
      </Container>
      <HomepageProductTemplate />

      <div className="homepageImpactPrograms">
        <div className="homepageImpactProgramsOverlay">
          <div className="homepageImpactProgramsLeft">
            <img className="homepageImpactProgramsLogo" src={homepageImpactProgramsLogo}></img>
            <div className="homepageImpactProgramsText">Helping you <i>grow</i>, <br />one job at a time.</div>

            <Button 
              variant="outlined"
              size="medium"
              color="inherit"
              className="homepageImpactProgramsButton" 
              onClick={() => window.location.href = "/visitor/impact-programs/impact-programs-overview"}>
                LEARN MORE &#62;
            </Button>
          </div>

          
          <div className="homepageImpactProgramsRight">
            <div className="homepageImpactProgramsRightItem">
              <img className="homepageImpactProgramsPlus" src={homepageImpactProgramsPlusImg}></img>
              <div className="homepageImpactProgramsPlusText">LANSING <strong>MARKETING</strong> SERVICES</div>
            </div>

            <div className="homepageImpactProgramsRightItem">
              <img className="homepageImpactProgramsPlus" src={homepageImpactProgramsPlusImg}></img>
              <div className="homepageImpactProgramsPlusText"><strong>LOYALTY</strong> LINK</div>
            </div>
            
            <div className="homepageImpactProgramsRightItem">
              <img className="homepageImpactProgramsPlus" src={homepageImpactProgramsPlusImg}></img>
              <div className="homepageImpactProgramsPlusText"><strong>FINANCING</strong> SOLUTIONS</div>
            </div>
            
            <div className="homepageImpactProgramsRightItem">
              <img className="homepageImpactProgramsPlus" src={homepageImpactProgramsPlusImg}></img>
              <div className="homepageImpactProgramsPlusText"><strong>LEAD</strong> GENERATION</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
