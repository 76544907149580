import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import warehouseReducer from "./slices/warehouseSlice";
import builderIOReducer from "./slices/builderIOSlice";
import { baseApi } from "./services/baseApi"; // Import your API middleware

export const store = configureStore({
  reducer: {
    userState: userReducer,
    warehouseState: warehouseReducer,
    builderIOState: builderIOReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware), // Add the API middleware
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;