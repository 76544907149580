import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import tokenProvider from "../../auth/tokenProvider";
import { Logger } from "../../logger/logger";

export const RESPONSE_STATUS_401 = 401;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_STOREFRONT_API_URL,
  credentials: "include",
  prepareHeaders: async (headers) => {
    let token = tokenProvider.getToken();
    headers.set("Authorization", `Bearer ${token}`);

    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('AuthenticatedCustomerId='))
      ?.split('=')[1];

    headers.set('AuthenticatedCustomerId', cookieValue?.trim() ?? "");

    return headers;
  },
});

// Enhanced base query to handle 401 status and retry with refreshed token
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === RESPONSE_STATUS_401) {
    // try to get a new token
    const token = await tokenProvider.fetchToken();

    if (token) {
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Redirect to quicklogin
      window.location.href = process.env.REACT_APP_WEB_URL + "/quicklogin";
    }
  }

  //TODO: check base response error (success = false)
  if (result.error) {
    Logger.error(result.error);
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
