import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const usePermissions = ()=>{
    
    const permissions = useSelector((state: RootState) => state.userState.user.permissions);
    const isAdmin = useSelector((state: RootState) => state.userState.user.isAdmin);
    const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);

    return (...permissionsToCheck: string[]) => {
        return isAuthenticated ? !isAdmin ? permissions.some((permission) => permissionsToCheck.includes(permission)) : true : true;
    };
}

export default usePermissions;