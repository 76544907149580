import { SxProps } from "@mui/material";

const largeLayoutStyles: Record<string, SxProps> = {
   
    
    "smallLayout": {
       
    },
}

const buttonStyles: Record<string, SxProps> = {
    'contained-primary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--primary-main, #6EC0E9)',
        '&:hover': {
            background: 'var(--primary-hover, #5DB3E4)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-primary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--primary-main, #6EC0E9)',
        '&:hover': {
            background: 'var(--primary-hover, #5DB3E4)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-primary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--primary-main, #6EC0E9)',
        '&:hover': {
            background: 'var(--primary-hover, #5DB3E4)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-secondary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--secondary-main, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-dark, #FDB726)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--Lancing-Gold-200, #FFDA85)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-secondary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--secondary-main, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-dark, #FDB726)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--Lancing-Gold-200, #FFDA85)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-secondary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-contrast, #003A5C)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--secondary-main, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-dark, #FDB726)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--Lancing-Gold-200, #FFDA85)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-error-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--error-main, #D32F2F)',
        '&:hover': {
            background: 'var(--error-dark, #C62828)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--error-focusVisible, rgba(211, 47, 47, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-error-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--error-main, #D32F2F)',
        '&:hover': {
            background: 'var(--error-dark, #C62828)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--error-focusVisible, rgba(211, 47, 47, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-error-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--error-main, #D32F2F)',
        '&:hover': {
            background: 'var(--error-dark, #C62828)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--error-focusVisible, rgba(211, 47, 47, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-warning-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--warning-main, #EF6C00)',
        '&:hover': {
            background: 'var(--warning-dark, #EF6C00)',
        },
        '&:focus': {
            background: 'var(--warning-main, #EF6C00)',
            boxShadow: '0px 0px 0px 4px var(--warning-focusVisible, rgba(239, 108, 0, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-warning-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--warning-main, #EF6C00)',
        '&:hover': {
            background: 'var(--warning-dark, #EF6C00)',
        },
        '&:focus': {
            background: 'var(--warning-main, #EF6C00)',
            boxShadow: '0px 0px 0px 4px var(--warning-focusVisible, rgba(239, 108, 0, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-warning-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--warning-main, #EF6C00)',
        '&:hover': {
            background: 'var(--warning-dark, #EF6C00)',
        },
        '&:focus': {
            background: 'var(--warning-main, #EF6C00)',
            boxShadow: '0px 0px 0px 4px var(--warning-focusVisible, rgba(239, 108, 0, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-info-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--info-main, #0E557A)',
        '&:hover': {
            background: 'var(--info-dark, #003A5C)',
        },
        '&:focus': {
            background: 'var(--info-main, #0E557A)',
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-info-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--info-main, #0E557A)',
        '&:hover': {
            background: 'var(--info-dark, #003A5C)',
        },
        '&:focus': {
            background: 'var(--info-main, #0E557A)',
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-info-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--info-main, #0E557A)',
        '&:hover': {
            background: 'var(--info-dark, #003A5C)',
        },
        '&:focus': {
            background: 'var(--info-main, #0E557A)',
            boxShadow: '0px 0px 0px 4px var(--primary-focusVisible, #90D2F1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-success-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--success-main, #2E7D32)',
        '&:hover': {
            background: 'var(--success-dark, #1B5E20)',
        },
        '&:focus': {
            background: 'var(--success-main, #2E7D32)',
            boxShadow: '0px 0px 0px 4px var(--success-focusVisible, rgba(46, 125, 50, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-success-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--success-main, #2E7D32)',
        '&:hover': {
            background: 'var(--success-dark, #1B5E20)',
        },
        '&:focus': {
            background: 'var(--success-main, #2E7D32)',
            boxShadow: '0px 0px 0px 4px var(--success-focusVisible, rgba(46, 125, 50, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-success-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-contrast, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--success-main, #2E7D32)',
        '&:hover': {
            background: 'var(--success-dark, #1B5E20)',
        },
        '&:focus': {
            background: 'var(--success-main, #2E7D32)',
            boxShadow: '0px 0px 0px 4px var(--success-focusVisible, rgba(46, 125, 50, 0.30))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-text-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--Lansing-Gray-300, #E0E0E0)',
        '&:hover': {
            background: 'var(--Lansing-Gray-100, #F5F5F5)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-text-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--Lansing-Gray-300, #E0E0E0)',
        '&:hover': {
            background: 'var(--Lansing-Gray-100, #F5F5F5)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-text-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--Lansing-Gray-300, #E0E0E0)',
        '&:hover': {
            background: 'var(--Lansing-Gray-100, #F5F5F5)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-white-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-white-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'contained-inherit-white-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 4px var(--info-light-hover, #B7D6E1)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-primary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--primary-dark, #5DB3E4)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-primary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--primary-dark, #5DB3E4)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-primary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--primary-dark, #5DB3E4)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-secondary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-main, #FEC139)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--secondary-outlinedBorder, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-secondary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-main, #FEC139)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--secondary-outlinedBorder, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-secondary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-main, #FEC139)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--secondary-outlinedBorder, #FEC139)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '2px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-error-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-main, #D32F2F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--error-outlinedBorder, rgba(211, 47, 47, 0.50))',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-error-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-main, #D32F2F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--error-outlinedBorder, rgba(211, 47, 47, 0.50))',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-error-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-main, #D32F2F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--error-outlinedBorder, rgba(211, 47, 47, 0.50))',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-warning-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-main, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--warning-outlinedBorder, rgba(239, 108, 0, 0.50))',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-warning-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-main, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--warning-outlinedBorder, rgba(239, 108, 0, 0.50))',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-warning-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-main, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--warning-outlinedBorder, rgba(239, 108, 0, 0.50))',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-info-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--info-outlinedBorder, #0E557A)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            border: '2px solid var(--info-dark, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-info-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--info-outlinedBorder, #0E557A)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            border: '2px solid var(--info-dark, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-info-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--info-outlinedBorder, #0E557A)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            border: '2px solid var(--info-dark, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-success-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--success-outlinedBorder, rgba(46, 125, 50, 0.50))',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-success-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--success-outlinedBorder, rgba(46, 125, 50, 0.50))',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-success-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--success-outlinedBorder, rgba(46, 125, 50, 0.50))',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '2px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-inherit-text-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--blueGrey-800, #37474F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--blueGrey-200, #B0BEC5)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--blueGrey-200, #B0BEC5)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-inherit-text-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--blueGrey-800, #37474F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--blueGrey-200, #B0BEC5)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--blueGrey-200, #B0BEC5)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-inherit-text-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--blueGrey-800, #37474F)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--blueGrey-200, #B0BEC5)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--blueGrey-200, #B0BEC5)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-inherit-white-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--common-white-hover, rgba(255, 255, 255, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'outlined-inherit-white-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--common-white-hover, rgba(255, 255, 255, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-inherit-white-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--common-white-main, #FFF)',
        '&:hover': {
            background: 'var(--common-white-hover, rgba(255, 255, 255, 0.04))',
        },
        '&:focus': {
            border: '3px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-mint-green-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--tertiary---mint-main, #17B189)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--tertiary---mint-main, #17B189)',
        '&:hover': {
            background: 'var(--tertiary---mint-light, #9EDCCC)',
        },
        '&:focus': {
            border: '2px solid var(--tertiary---mint-dark, #0B8566)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-mint-green-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--tertiary---mint-main, #17B189)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--tertiary---mint-main, #17B189)',
        '&:hover': {
            background: 'var(--tertiary---mint-light, #9EDCCC)',
        },
        '&:focus': {
            border: '2px solid var(--tertiary---mint-dark, #0B8566)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'outlined-mint-green-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--tertiary---mint-main, #17B189)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        border: 'var(--none, 1px) solid var(--tertiary---mint-main, #17B189)',
        '&:hover': {
            background: 'var(--tertiary---mint-light, #9EDCCC)',
        },
        '&:focus': {
            border: '2px solid var(--tertiary---mint-dark, #0B8566)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
            border: 'var(--none, 1px) solid var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
        },
    },
    'text-primary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '1px solid var(--primary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-primary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '1px solid var(--primary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-primary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary-dark, #5DB3E4)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--primary-hover-light, #BAE4F7)',
        },
        '&:focus': {
            border: '1px solid var(--primary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-secondary-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-dark, #FDB726)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '1px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-secondary-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-dark, #FDB726)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '1px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-secondary-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--secondary-dark, #FDB726)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--secondary-light-hover, #FFE8B4)',
        },
        '&:focus': {
            border: '1px solid var(--secondary-contrast, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-error-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-dark, #C62828)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-error-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-dark, #C62828)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-error-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--error-dark, #C62828)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--error-hover, rgba(211, 47, 47, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--error-dark, #C62828)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-warning-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-dark, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-warning-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-main, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-warning-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--warning-main, #EF6C00)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--warning-hover, rgba(239, 108, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--warning-dark, #EF6C00)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-info-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            border: '1px solid var(--info-dark, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-info-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            display: 'inline-flex',
            padding: '10px 14px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-info-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--info-main, #0E557A)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--info-light-hover, #B7D6E1)',
        },
        '&:focus': {
            border: '1px solid var(--info-dark, #003A5C)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-success-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-success-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-success-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--success-main, #2E7D32)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--success-hover, rgba(46, 125, 50, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--success-dark, #1B5E20)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-text-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-black-outlinedBorder, rgba(0, 0, 0, 0.50))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-text-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-black-outlinedBorder, rgba(0, 0, 0, 0.50))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-text-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-black-outlinedBorder, rgba(0, 0, 0, 0.50))',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-white-large': {
        display: 'inline-flex',
        padding: '16px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-white-medium': {
        display: 'inline-flex',
        padding: '10px 14px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
    'text-inherit-white-small': {
        display: 'inline-flex',
        padding: '4px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--common-white-main, #FFF)',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: 'var(--sans-body, Gotham-Medium)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
        textTransform: 'uppercase',
        borderRadius: 'var(--borderRadius, 4px)',
        '&:hover': {
            background: 'var(--action-hover, rgba(0, 0, 0, 0.04))',
        },
        '&:focus': {
            border: '1px solid var(--common-white-main, #FFF)',
        },
        '&:disabled': {
            color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
        },
    },
};


export default buttonStyles;

