import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ButtonInfo } from '../../models/builderIO/types';

interface BuilderIOState {
    button: ButtonInfo
}

const initialState: BuilderIOState = {
    button:{
        actionName: null,
        actionDateTime: null
    }
};

const builderIOSlice = createSlice({
    name: 'builderIO',
    initialState,
    reducers: {
        buttonClick: (state, action: PayloadAction<string>) => {
            state.button.actionName = action.payload;
            state.button.actionDateTime = (new Date()).toUTCString();
        },
    },
});

export const { buttonClick } = builderIOSlice.actions;

export default builderIOSlice.reducer;

