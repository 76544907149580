import builder, { Builder } from "@builder.io/react";
import Button, { buttonRegistration } from "./custom-components/Button/Button";
import Text, { textRegistration } from "./custom-components/Text/Text";
import colorTokens from "../../assets/css/styles/colorTokens";
import spacesTokens from "../../assets/css/styles/spaceTokens";
import fontTokens from "../../assets/css/styles/fontTokens";

builder.init(process.env.REACT_APP_BUILDER_KEY!);

Builder.registerComponent(Button, buttonRegistration);

Builder.registerComponent(Text, textRegistration);

Builder.register("editor.settings", {
    designTokens: {
        colors: colorTokens,
        spacing: spacesTokens,
        fontFamily: fontTokens
    },
    // Enable strict mode to enforce these tokens
    styleStrictMode: false,
});