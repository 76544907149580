import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const HeaderWarning: React.FC = () => {
    const {
        globalMessage: message
    } = useSelector((state: RootState) => state.warehouseState.warehouseInfo);

    let headerStyle = {
        color: "#fff",
        textAlign: "center" as const,
        height: "35px",
        lineHeight: "30px",
        fontWeight: "bold",
        backgroundColor: "#fbb040"
    }

    return message == null ? <></> : <div style={headerStyle}>{message}</div>
}

export default HeaderWarning;