import React from 'react';

interface TextProps {
    text: string;
}

const Text: React.FC<TextProps> = ({ text = "Enter some text here..."}) => {
    return (
        <span className='builderiotext' style={{ margin: "0px", padding: "0px"}} dangerouslySetInnerHTML={{ __html: text }} />
    );
};

export default Text;

export const textRegistration = {
    name: "Text ",
    inputs: [
        { 
            name: "text",
            type: "richText",
        },
    ],
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Add a text component to the page',
}